import React from "react";
import PropTypes from "prop-types";
import { getLinkToProject } from "../../common/util/pathUtils";

const SingleColumn = ({ project, showLearnMore }) => {
    const linkToProject = getLinkToProject(project.code);

    return <div className="w-100 con-justify text-left text-center">
				<div className="up">
					<div className="img-cover img-1 w-100">
						<a href={linkToProject}><img src={project.thumbnailImageUrl} /></a>
					</div>
					<div className="px-30">
						<h5><a className="text-primary text-uppercase" href={linkToProject}>{project.name}</a></h5>
						<h6 className="text-uppercase">{project.locationDescription}</h6>
						<p>{project.shortDescription}</p>
					</div>
				</div>
				<div className="down px-30">
					{showLearnMore &&
					<a href={linkToProject} className="btn btn-primary btn-sml w-100">Learn more</a>
					}
				</div>
			</div>
}

SingleColumn.propTypes = {
    project: PropTypes.object,
    showLearnMore: PropTypes.bool
}

export default SingleColumn;