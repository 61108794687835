import React, { useState, useEffect, useRef } from 'react';
import Accordian from '../../common/components/Accordian/Accordian.js'
import logo from "../../common/media/cc_logo_white.svg";
import { formatNumber } from "../../common/util/numberFormatter";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Popover from "../../common/components/popover/popover.js";
import { projects as getProjects } from '../../common/api/projects';
import ProjectsList from "../Projects/ProjectsList";
import logoWhite from '../../common/media/cc_logo_white.svg';
import logoGrey from '../../common/media/cc-logo-grey.svg';

import Modal from "../../common/components/modal/Modal";
import {getMyCCBaseUrlWithMerchantCode} from "../../common/util/pathUtils";
import { footerLinks } from '../../common/util/uiConfigs/footerConfiguration.js';
import * as impact from "../../common/api/impact";
import {project} from "../../common/api/impact";

const LandingPage = ({ merchantCode, fetchedImpact, oneOfConfig }) => {
    const [projects, setProjects] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {setIsModalVisible(true);};
    const closeModal = () => {
        if (isModalVisible) setIsModalVisible(false);
    }
    
    const numberOfContributions = fetchedImpact && !fetchedImpact.error && formatNumber(fetchedImpact.numberOfContributions?.value);
    const carbonOffsetImpact = fetchedImpact && !fetchedImpact.error && formatNumber(Math.round(fetchedImpact.carbonOffsetImpact?.value));
    const carbonOffsetImpactUnit = fetchedImpact && !fetchedImpact.error && fetchedImpact.carbonOffsetImpact?.unit.toUpperCase()
    const treeGrowthYears = fetchedImpact && !fetchedImpact.error && formatNumber(fetchedImpact.treeGrowthYears?.value);
    
    const communityProjectImpact = fetchedImpact && !fetchedImpact.error && formatNumber(Math.round(fetchedImpact?.moneyImpact?.value + 101650));
    const communityContributionImpact = fetchedImpact && !fetchedImpact.error && formatNumber(Math.round(fetchedImpact?.moneyImpact?.value));
    const communityContributionCurrency = fetchedImpact && !fetchedImpact.error && fetchedImpact.moneyImpact?.currency;
    
    const treeMetric = `An average tree captures about 20kg of carbon dioxide per year, and about 1 tonne over its lifetime. By funding projects that offset carbon emissions, we are having the same impact as ${treeGrowthYears} trees have in one year.`;
    
        
    useEffect(() => {
        getProjects(null, merchantCode)
            .then(response => response.json())
            .then(data => setProjects(data))
            .catch(setProjects);

    }, []);

    return (
        <div onClick={closeModal}>
            <div className="transparent-header">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-auto">
                            <a href="https://www.rnzys.org.nz/" target="_blank">
                                <img src={`https://res.cloudinary.com/carbonclick/image/upload/v1660122239/Enterprise/RNZYS/rebrand-logo_on1klf.svg`} style={{height: '120px'}} />
                            </a>
                        </div>
                    </div>
                                        
                </div>
            </div>

            <div className="heroSection">
                <div className="container">
                    <div className="heroContaint">
                        <h1 className="mt-0 text-white">RETAIN WHAT IS IMPORTANT <br/>RESTORE WHAT IS DEGRADED</h1>
                        <p className="mt-0 text-white">Help us take action on climate change by supporting our Kawau Island Legacy Project and high quality carbon offsetting projects. </p>
                        <a onClick={() => { showModal() }} className="btn btn-primary">Contribute now</a>
                        <div className="d-flex align-items-center pwdBy text-white mt-4">
                            <p className="my-0 mr-3">Powered by</p>
                            <img src={logoWhite} style={{ height: 24 }} alt="Powered by carbonclick" />
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ paddingTop: '80px' }}>
                <div className="container text-center">
                    <p className="text-brown subtitle">
                        We partnered with CarbonClick to give you the tools to take climate action. Offset your personal footprint through 
                        global certified carbon offsetting projects and support local by contributing to our Kawau Island legacy project.
                    </p>
                </div>
            </div>

            <div className="progressSection text-center text-brown">
                <div className="container-cus bg-primary p-4 box-shadow">
                    <h2 className="">Together we can fight climate change</h2>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="icon-bx">
                                <img src="https://res.cloudinary.com/carbonclick/image/upload/v1660124082/Enterprise/RNZYS/trees_s8j6ku.svg" alt="71% of the earths surface is covered by oceans" />
                                <h3 className="impact-value">${communityProjectImpact}</h3>
                                <p >Donated to the Kawau Island Legacy project</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="icon-bx">
                                <img src="https://res.cloudinary.com/carbonclick/image/upload/v1660124082/Enterprise/RNZYS/clouds_v1jqz6.svg" alt="71% of the earths surface is covered by oceans" />
                                <h3 className="impact-value">{carbonOffsetImpact}</h3>
                                <p >Kilograms of Carbon Emissions Offset</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="icon-bx">
                                <img src="https://res.cloudinary.com/carbonclick/image/upload/v1660124082/Enterprise/RNZYS/hand_klwi1y.svg" alt="71% of the earths surface is covered by oceans" />
                                <h3 className="impact-value">{numberOfContributions}</h3>
                                <p>Individual Contributions</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="clgSection">
                <div className="container">
                    <div className="text-center secHead">
                        <h2 className="text-primary title">KAWAU ISLAND LEGACY PROJECT</h2>
                        <p className="subtitle mw-980px">Our vision is to create a native habitat for all to enjoy and one that Kiwi and other native birdlife will return to the RNZYS site on Kawau Island.</p>
                    </div>
                    <div className="row secbdy">
                        <div className="col-lg-6 text-lg-right text-center">
                            <div className="ClgImg">
                                <img src="https://res.cloudinary.com/carbonclick/image/upload/v1660125073/Enterprise/RNZYS/images-section_a2amrp.png" alt="KAWAU ISLAND LEGACY PROJECT" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="padLeftTxt">
                                <p className="mt-0">The RNZYS site is located within Bon Accord Harbour, Kawau Island. It is the south western headland of Smelting House Bay, immediately
                                    adjacent to the historic copper smelting site.  The property is largely scrubby coastal forest, which has been impacted by grazing wallabies
                                    and invasive plants, including some areas of large pine and macrocarpa trees.</p>
                                <p>The RNZYS proposal is to upgrade the facilities on the site, including improving the amenity and ecological value of the landscape, 
                                    restore the ngahere mauri (forest lifeforce) and providing habitat for the threatened native species such as Kiwi and Weka that live on Mawau Island.
                                </p>
                                <p>
                                    The current invasive tree species need to be removed from the site and walking paths created clearing the land for extensive planting of native species. 
                                    <b>The project will cost up to $120,000 in year one and will kick start this long term legacy project effectively.</b>
                                </p>
                                <p>Due consideration to the history of the site is required as it has been a significant part of Auckland’s recreational boating world since the RNZYS was formed in 1871.</p>
                                <p>Learn more about the Kawau Island Legacy initiative <b>
                                    <a className="text-dark" target="_blank" rel="noopener noreferrer" href="https://www.rnzys.org.nz/kawau-project/ ">here</a></b>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ padding: '80px 0' }}>
                <div className="container text-center">
                    <p className="text-brown title mw-980px">
                        Help the planet with a simple click. Mitigate your personal footprint through global certified 
                        carbon offsetting projects and support local by contributing to our Kawau initiative.
                    </p>
                </div>
            </div>
           
            <div className="darkImgSection secondDark">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="darkImgTxtBox text-center">
                                <h2 className="text-white subtitle mb-3">Offset your own personal footprint</h2>
                                <p className="text-white">Measure your personal carbon footprint with CarbonClick’s calculator. Choose between regular contributions to match your lifestyle or a one-off contribution to make an impact today.</p>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${getMyCCBaseUrlWithMerchantCode(merchantCode)}/calculator`}
                                    className="btn btn-primary"
                                >
                                    Calculate my personal footprint
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="darkImgTxtBox text-center">
                                <h2 className="text-white subtitle mb-3">Contribute an amount of your choice</h2>
                                <p className="text-white m-0">Select how much you wish to contribute with a one-time purchase. The average person's annual carbon footprint is 15.3t of CO2.</p>
                                <p className='m-0 indent'>.</p>
                                <a onClick={() => { showModal() }} className="btn btn-primary">Contribute now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {Array.isArray(projects) && (
            <div className="projects projects-sec text-center">
                <div className="container-cus">
                    <div className="projectHead">
                        <h2 className="text-primary title">SUPPORTING PROJECTS THAT FIGHT CLIMATE CHANGE</h2>
                        <p className="subtitle pb-3">RNZYS, in collaboration with CarbonClick, supports only the highest quality carbon offsetting projects to enable you to make the biggest impact possible.</p>
                    </div>
                    <ProjectsList title={null} projects={projects} />
                </div>
            </div>
            )}

            <div className="faq-sec">
                <div className="container-cus">
                    <div className="row align-items-center">
                        <div className="col-md-10 mx-auto">
                            <h2 className="text-primary text-center title">CLIMATE FRIENDLY FAQ’s</h2>
                            <div className="mb-3 center text-align-start">
                                <Accordian title={"Who are CarbonClick?"} >
                                    CarbonClick is a New Zealand technology company that exists to reverse climate change. By making carbon offsetting simple, trustworthy and cost effective,  we empower businesses and their customers with the tools to start tackling their carbon emissions.
                                </Accordian>
                                <Accordian title={"What does carbon offsetting do?"}>
                                    <span className='mb-2 d-block'>CarbonClick follows industry best practice to ensure that the offsets provided have a real, measurable impact on mitigating climate change.</span>
                                    Projects are scrutinised against their independently verified selection methodology, which uses many of the same stringent requirements of ICROA Code of Best Practice, such as true additionality, ensuring credible baseline reporting and verifying project developers integrity. Only projects that have considerable co-benefits are considered, 
                                    such as improving biodiversity, creating sustainable jobs, empowering women and minorities and improving community health and wellbeing.
                                </Accordian>
                                <Accordian title={"What are carbon credits?"} >
                                    A carbon credit is mechanism used to measure and support projects that fight climate change. For every tonne of CO2 that a project manages to absorb or otherwise reduce, a carbon credit is issued. Carbon credits are certified by international standards and held in registries like the Gold Standard registry (international credits) and can be purchased to reduce the carbon impact of an initiative or activity.
                                </Accordian>
                                <Accordian title={"Where does the money go?"} >
                                    The money paid to CarbonClick for carbon credits goes towards certified carbon offset projects that have met the highest standards. In some areas CarbonClick also supports local sustainability initiatives that do not qualify for carbon credits. All projects have been vetted by CarbonClick. The funds are distributed amongst carbon offset suppliers and local initiatives. CarbonClick takes a margin on the contribution for sourcing projects, quality assurance, administration and transaction fees.
                                </Accordian>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className="w-100 footer-img" >
                <div className="container">
                    <div className="footerImg text-brown">
                        <div className="footer-width">
                            <p className="footer-powered">POWERED BY</p>
                                <div className="d-flex">
                                    <div>
                                        <a href={footerLinks?.carbonclick} target="_blank" rel="noopener noreferrer">
                                            <img style={{ height: 24 }} className="footer-logo" src={logoGrey} alt="carbonclick" />
                                        </a>
                                    </div>
                                    <div className="right">
                                        <a className="font-light medium" href={"https://my.carbon.click"} target="_blank" rel="noopener noreferrer">Log in to <u> My CarbonClick </u> to see the impact</a>
                                    </div>
                                </div>
                            <hr className="footer-hr"/>
                            <div className="row links bottom-links">
                                <div className="col">
                                    <div>
                                        <div className="mb-25"><a className="medium" href={footerLinks?.aboutCarbonClick} target="_blank" rel="noopener noreferrer">About CarbonClick</a></div>
                                        <div className="mb-25"><a className="medium" href={footerLinks?.faq} target="_blank" rel="noopener noreferrer">FAQs</a></div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div>
                                        <div className="mb-25"><a className="medium" href={footerLinks?.termsAndConditions} target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a></div>
                                        <div className="mb-25"><a className="medium" href={footerLinks?.privacyPolicy} target="_blank" rel="noopener noreferrer">Privacy policy</a></div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div>
                                        <div className="mb-25"><a className="medium" href={footerLinks?.contactUs} target="_blank" rel="noopener noreferrer">Contact us</a></div>
                                        <div className="mb-25"><a className="medium" href="mailto:hello+rnzys@carbonclick.com" target="_blank" rel="noopener noreferrer">support@carbonclick.com</a></div>
                                    </div>
                                </div>
                            </div>  
                        </div>  
                    </div>

                </div>
            </div>
            <Modal small={true} handleClose={() => setIsModalVisible(false)} show={isModalVisible}>
                <section className="mb-3 h-100">
                    <iframe
                        frameBorder="0"
                        allowFullScreen
                        src={`${getMyCCBaseUrlWithMerchantCode(merchantCode)}/purchase-offset?currency=NZD&cost=10`}>
                    </iframe>
                </section>
            </Modal>
        </div>
    )
}


export default LandingPage;